import React from "react"

function TextImage({ char1 = "B", char2 = "K" }) {
  return (
    <div className=" p-2 font-bold  text-3xl bg-gray-200 dark:bg-gray-800 rounded-md text-gray-600 dark:text-gray-300 ">
      {char1.toUpperCase()}
      {char2.toUpperCase()}
    </div>
  )
}

export default TextImage
