import React from "react"

export default function HeaderHeroLight({
  primaryHeadline,
  secondaryHeadline,
  descriptionText,
}) {
  return (
    <div>
      <h1>
        <span className=" block tracking-tight font-semibold text-3xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl ">
          <span className="block  text-primary ">{primaryHeadline}</span>
          {secondaryHeadline && (
            <span className={"pt-2 block text-accent"}>{secondaryHeadline}</span>
          )}
        </span>
      </h1>
      {descriptionText && (
        <div className="flex justify-center align-middle">
          <p className=" mt-2 sm:mt-4 text-secondary sm:text-sm md:text-base lg:text-lg xl:text-xl xl:max-w-3xl md:max-w-3xl ">
            {descriptionText}
          </p>
        </div>
      )}
    </div>
  )
}
