import ImageWrapper from "@common/imageWrapper"
import React from "react"
import { useRouter } from "next/router"
import { getCountry } from "../../lib/navigation"
import { getCategoryList, getListsList } from "../../network/network"
import { Container8xl } from "../common/container"
import HeroWithLargeImage from "../common/landing/heroWithLargeImage"
import Svg from "../common/svg"
import SampleReports from "./sampleReports"
import { hitUrl, isNullOrEmpty } from "components/common/lib/util"

function TriLandingUnit() {
  const router = useRouter()
  let country = getCountry(router)

  return (
    <div className="pt-0 xl:pt-6 lg:relative z-10">
      {" "}
      {/*className=" lg:h-[91.1vh] pt-0 xl:pt-6 lg:relative z-10">*/}
      {renderHeroUnit()}
      {renderReportUnit()}
      {/*renderArtUnit()*/}
    </div>
  )

  function renderHeroUnit() {
    return (
      <HeroWithLargeImage
        primaryHeadline="Amazon Review Analysis"
        descriptionText="Paste any product URL from Amazon US, Canada, UK or India."
        ctaText={"Summarize"}
        getStartedRouteLink="#"
        inputPlaceHolder={"Enter Amazon product URL"}
        inputType={"url"}
        inputContainerClass={
          " my-9 xl:my-16 sm:max-w-full lg:max-w-3xl xl:max-w-4xl sm:mx-auto "
        }
        onSubmit={handleTriSubmit}
      />
    )
  }
  function renderReportUnit() {
    return (
      <Container8xl>
        <div className="z-20 relative pb-10">
          <SampleReports />
        </div>
      </Container8xl>
    )
  }

  function renderArtUnit() {
    return (
      <>
        <div className=" lg:absolute bottom-0 right-0 left-0 flex justify-center z-0 ">
          <div className="lg:h-[35vh] relative w-full flex justify-center">
            {renderGradient()}
            <div className="h-full absolute lg:static max-w-8xl w-full flex justify-center">
              {renderReportArt()}
            </div>
          </div>
          {renderBgImage()}
        </div>
      </>
    )
    function renderGradient() {
      return (
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0) 18%, #FFFFFF 30%)",
          }}
          className="h-1/3 lg:h-[12vh] lg:h-[18vh] z-15 absolute bottom-0 right-0 left-0 flex justify-center "
        />
      )
    }

    function renderBgImage() {
      return (
        <div className="lg:absolute bottom-0 right-0 left-0 flex justify-center h-[40vh] w-0 lg:w-full invisible lg:visible bg-transparent opacity-40">
          <ImageWrapper
            src={"/images/tri/tri_bg_art.svg"}
            blurDataURL={"/images/backupImages/landingPage_ss_fallback.jpg"}
            fill
            className="animate-mildFade object-contain"
            placeholder="blur"
          />
        </div>
      )
    }

    function renderReportArt() {
      return (
        <div
          style={{
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.57)",
          }}
          className=" w-full h-full rounded-t-2xl bg-background mx-4 sm:mx-6 z-10 p-5 "
        >
          <div className="flex justify-between align-middle">
            <div>
              <p className="text-base lg:text-lg font-bold">BATTERY</p>
              <p className="text-base lg:text-lg font-normal">
                charge, pods, life, lasts, hold
              </p>
            </div>
            <div className="flex  justify-center align-middle">
              <Svg name="comment" classes=" lg:h-7 w-6 lg:w-7 mr-2" />
              <p className="relative top-0 text-base lg:text-lg font-bold">11132</p>
            </div>
          </div>
          {renderBar()}
          {renderComments()}
        </div>
      )
      function renderBar() {
        return (
          <div className="flex justify-center gap-1 py-5">
            <div
              style={{
                width: "60%",
              }}
              className={"rounded-l-2xl text-white bg-green-250 px-5 "}
            >
              +60%
            </div>
            <div
              style={{
                width: "40%",
              }}
              className={"rounded-r-2xl text-white bg-red-500 text-right px-5 "}
            >
              -40%
            </div>
          </div>
        )
      }
      function renderComments() {
        const comments = [
          {
            text: "I have had these for 2 years now, the battery life is still just as amazing...",
          },
          {
            text: "It’s a decent product with good battery life and nicely works with my apple devices...",
          },
          {
            text: "Battery life is good, but can be better...",
          },
        ]
        return (
          <>
            {comments.map((comment, i) => (
              <div
                key={i}
                className={
                  " justify-start pt-3 shrink" +
                  (i > 0 ? " hidden sm:flex " : " flex")
                }
              >
                <Svg
                  name="comment"
                  classes=" h-6 lg:h-7 w-6 lg:w-7 mr-2 text-gray-700 dark:text-gray-400 relative top-1 lg:top-0 "
                />
                <p className=" text-base lg:text-lg font-normal text-gray-700 dark:text-gray-400">
                  {comment.text}
                </p>
              </div>
            ))}
          </>
        )
      }
    }
  }

  // <--- REDIRECT URL START --->
  function handleTriSubmit(query) {
    if (!isValidUrl(query)) {
      const searchQuery = cleanQuery(query)
      router.push(`/${country}/s/${searchQuery}/1`)
    } else {
      const productId = getProductId(query)
      if (typeof productId == "undefined") {
        hitUrl(
          `https://docs.google.com/forms/d/e/1FAIpQLSeDpEpjj96Las9Owpz6t33P774Wc-GuLpQpqbGdomoE4HtaoQ/formResponse?usp=pp_url&entry.878228989=${query}&submit=Submit`
        )
      }
      window.open(`/${country}/q/${productId}?ref=ondemand`)
    }
  }

  function isValidUrl(url) {
    try {
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  }

  function cleanQuery(input) {
    // SEARCH QUERY CLEANUP
    // 1. convert all special characters to space
    let searchQuery = input.replace(/[^a-z0-9]/gi, " ")

    // 2. replace multiple adjacent spaces with single space
    searchQuery = searchQuery.replace(/\s\s+/g, " ")

    // 3. convert space & special characters to underscore
    searchQuery = searchQuery.replace(/[^a-z0-9]/gi, "_")

    // 4. strip last _ if present
    if (searchQuery[searchQuery.length - 1] == "_")
      searchQuery = searchQuery.substring(0, searchQuery.length - 1)

    // 5. strip first _ if present
    if (searchQuery[0] == "_")
      searchQuery = searchQuery.substring(1, searchQuery.length)

    return "text-" + searchQuery
  }

  function getProductId(url) {
    if (typeof url == "undefined" || url == null) return undefined

    // TODO - lowercase pid is not detected
    const src_regex = {
      FK: url.match("^http[s]*://[wdl]+.flipkart.com/.*pid=([A-Z0-9]+)(&.*)?$"),
      "AZ-IN": url.match("^http[s]*://[a-z]*[.]?amazon.in/.*/([A-Z0-9]+)([/?].*)?$"),
      "AZ-US": url.match(
        "^http[s]*://[a-z]*[.]?amazon.com/.*/([A-Z0-9]+)([/?].*)?$"
      ),
      "AZ-UK": url.match(
        "^http[s]*://[a-z]*[.]?amazon.co.uk/.*/([A-Z0-9]+)([/?].*)?$"
      ),
      "AZ-CA": url.match("^http[s]*://[a-z]*[.]?amazon.ca/.*/([A-Z0-9]+)([/?].*)?$"),
      STM: url.match(
        "^http[s]*://[a-z]*[.]?steam(?:powered|community).com.*/app/([0-9]+)(/.*)?$"
      ),
    }

    for (const key in src_regex) {
      const matches = src_regex[key]
      if (matches != null && matches.length > 0) return key + "_" + matches[1]
    }
  }
  // <--- REDIRECT URL END --->
}

export default TriLandingUnit

export async function getFooterLinks(country, footerLinks) {
  const _categoriesList = await _getCategoryList(country)
  const _listsList = await _getListsList(country)
  let seoLinks = {}
  seoLinks = {
    name: "Categories",
    links: !isNullOrEmpty(_categoriesList) ? [..._categoriesList] : null,
  }
  return {
    ...footerLinks,
    seoLinks,
  }
}

async function _getCategoryList(country) {
  let { data, resCode } = await getCategoryList()
  if (data && resCode == 200) {
    return getFormatedRelevantCatList(getReleventList(country, data, 100), country)
  }
}

function getFormatedRelevantCatList(data, country) {
  var result = []
  if (!data) result
  for (var i = 0; i < data.length; i++) {
    var item = data[i]
    var entry = {
      name: item["name"],
      link: "/" + country + "/c/" + item["url_name"] + "/" + item["key"],
    }
    result.push(entry)
  }
  return result
}

async function _getListsList(country) {
  let { data, resCode } = await getListsList()
  if (data && resCode == 200) {
    return getFormatedRelevantListsList(getReleventList(country, data, 10), country)
  }
}

function getFormatedRelevantListsList(data, country) {
  var result = []
  if (!data) result
  for (var i = 0; i < data.length; i++) {
    var item = data[i]
    var entry = {
      name: item["cat_name"],
      link: "/" + country + "/c/" + item["cat_url_name"] + "/" + item["list_id"],
    }
    result.push(entry)
  }
  return result
}

function getReleventList(country, lists, maxCount) {
  let localLists = []

  if (typeof country == "undefined" || typeof lists == "undefined") return localLists

  Object.keys(lists).forEach((key) => {
    let listDetails = lists[key]
    if (
      typeof listDetails != "undefined" &&
      typeof listDetails.country_id != "undefined" &&
      listDetails.country_id == country
    )
      localLists.push({ ...lists[key], key })
  })
  if (maxCount && localLists.length > maxCount)
    localLists = getRandomSubarray(localLists, maxCount)

  return localLists
}

function getRandomSubarray(arr, size) {
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - size,
    temp,
    index
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random())
    temp = shuffled[index]
    shuffled[index] = shuffled[i]
    shuffled[i] = temp
  }
  return shuffled.slice(min)
}
