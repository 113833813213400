import React from "react"
import ImageWrapper from "@common/imageWrapper"
import TextImage from "@common/textImage"
import TooltipWrapper from "@common/tooltip/tooltipWrapper"
import { CardProps } from "@common/types"

// TODO - wrap in card. Not Button.
// TODO - why's this differrnt from iconCard. combine both.

export default function FlatCard({
  id,
  cardWidthClasses,
  cardImage,
  initials = ["P", "O"],
  cardName,
  cardDescription,
  fallBackImg,
  cardUrl,
  wrapLink = false,
  newTab = false,
  onCardClick,
  showTooltip = true,
}: CardProps) {
  const handleClick = () => {
    if (onCardClick) onCardClick(id)
  }

  function renderFlatCardContent() {
    return (
      <button
        className="w-full border py-2 px-4 rounded-md cursor-pointer hover:bg-muted transition-colors"
        onClick={handleClick}
      >
        <div className=" w-full flex justify-start items-center">
          <div>
            {typeof cardImage == "string" ? (
              <div className="w-14 h-14 relative">
                <ImageWrapper
                  src={cardImage}
                  fallbackUrl={fallBackImg}
                  blurDataURL="/images/backupImages/img-placeholder.svg"
                  className="object-contain"
                  alt={cardName ?? ""}
                />
              </div>
            ) : (
              <TextImage char1={initials[0]} char2={initials[1]} />
            )}
          </div>

          <div className="p-2  text-left min-w-0">
            <TooltipWrapper content={cardName} disabled={!showTooltip}>
              <p
                className={
                  "text-gray-800 dark:text-gray-300 font-semibold text-md truncate "
                }
              >
                {cardName}
              </p>
            </TooltipWrapper>
            <p className={"text-gray-700 dark:text-gray-400 text-sm truncate "}>
              {cardDescription} &nbsp;
            </p>
          </div>
        </div>
      </button>
    )
  }

  return (
    <div key={id} className={cardWidthClasses || "md:w-72 lg:w-80 w-full"}>
      {wrapLink && cardUrl ? (
        <a href={cardUrl} rel="noreferrer" target={newTab ? "_blank" : "_self"}>
          {renderFlatCardContent()}
        </a>
      ) : (
        renderFlatCardContent()
      )}
    </div>
  )
}
