import React, { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Container8xl } from "../container"
import { Header } from "@common/header/headerUnit"

export default function FeaturesUnit({ headingText, subText, featuresData }) {
  // featuresData 👉 ARRAY OF OBJECT WITH header & subheader PROPS ⚠️ keep it even numbered
  const [isInView, setIsInView] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0.1,
  })
  useEffect(() => {
    if (!isInView && inView) {
      setIsInView(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])
  return (
    <div ref={ref}>
      <Container8xl>
        <div
          className={
            " max-h-full px-4 md:px-16 py-10 md:py-16  " +
            (isInView ? " animate-loadFadeUp" : "invisible")
          }
        >
          <div>
            <div className="sm:p-2 text-center">
              <Header variant="h4" alignment="center">
                <Header.MainHeader>{headingText}</Header.MainHeader>
                <Header.Description>{subText}</Header.Description>
              </Header>
            </div>
            <div
              className={`grid grid-cols-1 lg:grid-cols-3 sm:gap-5 md:gap-0 px-0 lg:px-14 mt-10 lg:mt-20`}
            >
              {featuresData.map(({ header, subheader }, index) => {
                return (
                  <div
                    className={
                      " rounded-lg md:rounded-none p-3 lg:p-5 xl:p-9  " +
                      (index % 2 == 0 ? " bg-gray-100 dark:bg-opacity-5" : " ")
                    }
                    key={index}
                  >
                    <p className="text-sm md:text-base font-semibold text-primary uppercase">
                      {header}
                    </p>
                    <p className="text-sm md:text-base font-normal text-secondary my-3">
                      {subheader}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Container8xl>
    </div>
  )
}
