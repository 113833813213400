import { Container8xl } from "../container"
import GetStarted from "./getStarted"
import PropTypes from "prop-types"
import HeaderHeroLight from "@common/header/headerHeroLight"

HeroWithLargeImage.propTypes = {
  primaryHeadline: PropTypes.string.isRequired,
  secondaryHeadline: PropTypes.string,
  descriptionText: PropTypes.string,
  renderCta: PropTypes.func,
  getStartedRouteLink: PropTypes.string,
  ctaText: PropTypes.string,
  ctaSubText: PropTypes.string,
  inputPlaceHolder: PropTypes.string,
  inputType: PropTypes.string,
  inputContainerClass: PropTypes.string,
  descriptionTextClass: PropTypes.string,
}
export default function HeroWithLargeImage({
  primaryHeadline,
  secondaryHeadline,
  descriptionText,
  renderCta,
  getStartedRouteLink,
  ctaText,
  ctaSubText,
  inputPlaceHolder,
  inputType,
  inputContainerClass,
  onSubmit,
}) {
  return (
    <Container8xl>
      <div className=" pt-16 xl:pt-10 md:px-0 lg:px-0 xl:px-0 mx-auto lg:mx-0 max-w-xl lg:max-w-none text-center">
        <HeaderHeroLight
          primaryHeadline={primaryHeadline}
          secondaryHeadline={secondaryHeadline}
          descriptionText={descriptionText}
        />

        {renderCta ?? (
          <div
            className={
              inputContainerClass ||
              "mt-8 mb-10 md:mb-24 sm:max-w-full  lg:max-w-lg xl:max-w-xl sm:mx-auto"
            }
          >
            <GetStarted
              routeLink={getStartedRouteLink}
              ctaText={ctaText}
              ctaSubText={ctaSubText}
              inputPlaceHolder={inputPlaceHolder}
              inputType={inputType}
              onSubmit={onSubmit}
            />
          </div>
        )}
      </div>
    </Container8xl>
  )
}
